/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * CartItemList is a functional React component that displays a list of items in the user's shopping cart.
 * It shows the name and address of the business for each item, the item details, and a button to reserve the item.
 * The reserve button is only shown if the business has a reservation email and the user is not on a mobile device.
 * If the user is on a mobile device, the reserve button is shown at the end of the list of items.
 *
 * @author James Nguyen, Yang Ming
 * @version January 15th, 2024
 */

import React from 'react'
import CartItemStore from './CartItemStore'

/**
 * Displays a list of items in the user's shopping cart.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.shoppingCart - The user's shopping cart.
 * @param {Object} props.location - The user's location.
 * @param {Function} props.handleUpdateCart - Function to update the quantity of an item in the cart.
 * @param {Function} props.handleDeleteCart - Function to delete an item from the cart.
 * @param {Function} props.setShowPopup - Function to show or hide the popup.
 * @param {Function} props.setSelectedStore - Function to set the selected store.
 * @param {boolean} props.isMobile - Whether the user is on a mobile device.
 *
 * @returns {JSX.Element} The rendered component.
 */

export default function CartItemList({
  shoppingCart,
  location,
  handleUpdateCart,
  handleDeleteCart,
  setShowPopup,
  setSelectedStore,
  isMobile,
  setIsCheckout,
}) {
  const cartData = shoppingCart?.data
  const handleReserve = async business_id => {
    setSelectedStore(business_id)
    setShowPopup(true)
  }
  const handleCheckout = async business_id => {
    setSelectedStore(business_id)
    setIsCheckout(true)
  }
  return (
    <div className='cart-content-list'>
      {cartData?.data?.map((item, index) => (
        <CartItemStore
          key={index}
          isMobile={isMobile}
          item={item}
          location={location}
          handleReserve={handleReserve}
          handleCheckout={handleCheckout}
          handleDeleteCart={handleDeleteCart}
          handleUpdateCart={handleUpdateCart}
        />
      ))}
    </div>
  )
}
