/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * CartItemCard is a functional React component that displays a card for an item in the user's shopping cart.
 * It shows the item's image, name, price, and a dropdown to select the quantity.
 * If the quantity is 10 or more, it shows an input field instead of the dropdown.
 * Additionally it implements a checkbox to select the item and a button to remove the item from the cart.
 *
 * @author James Nguyen, Yang Ming
 * @version November 11th, 2023
 */

import React from 'react'
import { formatCurrency } from '@utils/Helper'
import { getItemLink } from '@utils/Helper'
import MyImage from '@components/MyImage'

/**
 * Displays a card for an item in the user's shopping cart.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.item - The item to display.
 * @param {Function} props.handleUpdateCart - Function to update the quantity of the item in the cart or its selected status.
 * @param {Function} props.handleDeleteCart - Function to delete the item from the cart.
 *
 * @returns {JSX.Element} The rendered component.
 */

export default function CartItemCard({
  item,
  handleUpdateCart,
  handleDeleteCart,
}) {
  const [isShowInput, setIsShowInput] = React.useState(false)
  const [inputValue, setInputValue] = React.useState(item?.quantity)

  const handleRemove = async () => {
    await handleDeleteCart(item?._id)
  }

  const handleChangeQuantity = async value => {
    console.log('handleChangeQuantity', value.target.value)
    if (value.target.value === '10+') {
      setInputValue(item?.quantity)
      setIsShowInput(true)
    } else {
      setInputValue(value.target.value)
      setIsShowInput(Number(value.target.value) >= 10)
      await handleUpdateCart(item?._id, { quantity: value.target.value })
    }
  }

  const handleChecked = async () => {
    await handleUpdateCart(item?._id, { isSelected: !item?.isSelected })
  }

  const elements = Array.from({ length: 9 }, (_, index) => index)
  const link = getItemLink(item.item?._id, item?.item?.name)
  let variant = null
  if (item?.variant_id && item?.item?.variants?.length > 0) {
    variant = item?.item?.variants?.find(v => v.variant_id === item?.variant_id)
  }
  let image = item?.item?.image || '/item-default.png'
  if (item?.item?.images?.length > 0) {
    image =
      variant && variant?.image_id < item?.item?.images?.length
        ? item?.item?.images[variant?.image_id]
        : item?.item?.images[0]
  }
  if (!image && item.item?.images?.length > 0) {
    image = item.item?.images[0]
  }

  return (
    <div className='cart-item-line' key={item?._id}>
      <div className='cart-item-left'>
        <input
          type='checkbox'
          className='custom-checkbox'
          checked={item?.isSelected}
          onChange={handleChecked}
        />
        <a href={link} target='_blank' rel='noreferrer'>
          <MyImage
            src={image}
            alt={item?.item?.name}
            style={{
              objectFit: 'contain',
              mixBlendMode: 'multiply',
            }}
          />
        </a>
        <div className='cart-item-info'>
          <div className='cart-item-info-header'>
            <h4>
              <a href={link} target='_blank' rel='noreferrer'>
                {item?.item?.name}
              </a>
              {variant && <span>Option: {variant?.title}</span>}
              {/* <span>1 x 1.5oz</span> */}
            </h4>
            <div className='cart-item-buttons'>
              {isShowInput || item?.quantity >= 10 ? (
                <input
                  type='number'
                  name='quantity'
                  className='cart-item-input'
                  value={inputValue}
                  min={1}
                  onChange={value => setInputValue(value.target.value)}
                  onKeyDown={k => {
                    if (k.key === 'Enter') {
                      handleChangeQuantity(k)
                    }
                  }}
                />
              ) : (
                <select
                  className='cart-item-select'
                  onChange={handleChangeQuantity}
                  value={item?.quantity}
                >
                  {elements.map((number, index) => (
                    <option key={index} value={number + 1}>
                      {number + 1}
                    </option>
                  ))}
                  <option>10+</option>
                </select>
              )}
            </div>
          </div>
          <button onClick={handleRemove}>REMOVE</button>
        </div>
      </div>
      <div className='cart-item-price'>
        {item?.quantity === 1 ? (
          <h4>{formatCurrency(item?.item?.salePrice)}</h4>
        ) : (
          <>
            <h4>{formatCurrency(item?.item?.salePrice * item?.quantity)}</h4>
            <span>{formatCurrency(item?.item?.salePrice)}/ea</span>
          </>
        )}
      </div>
    </div>
  )
}
