/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * CartReservation is a functional React component that displays a form for the user to reserve items in their cart.
 * It shows the items in the cart, their quantities, and their prices.
 * It also provides input fields for the user to enter their name and email.
 * After the user submits the form, it shows a confirmation message.
 *
 * @author James Nguyen, Yang Ming
 * @version January 15th, 2024
 */

import React from 'react'
import { Input, Form } from 'antd'
import { formatCurrency } from '@utils/Helper'
import CartReserveItemList from './CartReserveItemList'

/**
 * Displays a form for the user to reserve items in their cart.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Object} props.userinfo - The user's information.
 * @param {boolean} props.isMobile - Whether the user is on a mobile device.
 * @param {Object} props.cartData - The data of the user's cart.
 * @param {Function} props.setShowPopup - Function to show or hide the popup.
 *
 * @returns {JSX.Element} The rendered component.
 */

export default function CartReservation({ form, cartData, handleSubmit }) {
  return (
    <Form
      ref={form}
      className='flex w-full flex-col items-center justify-center text-[18px]'
      onFinish={handleSubmit}
    >
      <div className='flex w-full max-w-[450px] flex-col justify-center'>
        <div className='py-5 text-center'>
          To reserve enter your name and email
        </div>
        <Form.Item
          name={'username'}
          rules={[
            {
              required: true,
              message: 'Please enter your name',
            },
          ]}
          className='w-full'
        >
          <Input
            placeholder='Name'
            className='rounded-full border-2 border-black'
          />
        </Form.Item>
        <Form.Item
          name={'userEmail'}
          rules={[
            {
              required: true,
              message: 'Please enter your email address',
            },
            {
              type: 'email',
              message: 'Please enter a valid email address',
            },
          ]}
          className='w-full'
        >
          <Input
            placeholder='Email'
            className='rounded-full border-2 border-black'
          />
        </Form.Item>
        <Form.Item name={'content'} className='w-full'>
          <Input.TextArea
            placeholder='Message (Optional)'
            className='rounded-[15px] border-2 border-black'
            rows={2}
          />
        </Form.Item>
        <CartReserveItemList cartData={cartData} />
        <div>
          <div className='flex flex-row items-center justify-between text-[15px] mt-[5px] border-t border-[#00000033]'>
            <span>Subtotal</span>
            <p className='font-light'>{formatCurrency(cartData?.total)}</p>
          </div>
          <div className='flex flex-row items-center justify-between text-[15px] '>
            <span>Taxes</span>
            <p className='font-light'>
              {formatCurrency(cartData?.total * 0.05)}
            </p>
          </div>
          <div className='flex flex-row items-center justify-between border-y border-[#00000033] py-[5px] text-[15px]'>
            <span>Total</span>
            <p className='text-[22px] font-light'>
              <span className='text-[14px]'>CAD</span>
              {formatCurrency(cartData?.total * 1.05)}
            </p>
          </div>
        </div>
        <div className='mt-[10px] text-center'>
          We'll email you to confirm that your reserved item has been put aside
          for you!
        </div>
        <Form.Item className='mt-[10px] flex w-full justify-center'>
          <button
            type='submit'
            className=' flex w-fit cursor-pointer justify-center rounded-full border-2 border-fitzba-bright-gold-static bg-fitzba-bright-gold-static px-10 py-[6px] text-white hover:bg-white hover:text-fitzba-bright-gold-static'
          >
            RESERVE NOW
          </button>
        </Form.Item>
      </div>
    </Form>
  )
}
