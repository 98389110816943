import './App.css'
import React, { useState, useEffect, Suspense } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { GlobalDebug } from './utils/Remove.consoles'
import Landing from './pages/home/Landing'
import InventoryImporter from './pages/inventoryImporter'
// import Zearch from './pages/zearch/Zearch'
import ZearchV2 from './pages/zearch/ZearchV2'
import PageTemplate from './pages/zearch/PageTemplate'
import Header from './components/Header'
import {
  loginToken,
  // getBusinessTypeList,
} from './services/api.service'
import {
  getUserStoreDistance,
} from './services/api.business.service'
import Policy from './pages/policy/Policy'
import Loading from './components/Loading'
import { useDispatch, useSelector } from 'react-redux'
import { setIsMobile } from './reducers/contentReducer'
import {
  logout,
  setUserinfo,
  setLocation,
  setSetting,
  setSearchHistory
} from './reducers/userReducer'
import AdditionalContactPage from './pages/contact/ContactPage'
import ItemDetail from './pages/itemDetail/ItemDetail'
import AccountRecover from './pages/user/AccountRecover'
import ResetPass from './pages/user/ResetPass'
import FAQ from './pages/faq/Faq'
import About from './pages/about/About'
import Contact from './pages/contact/Contact'
import ScrollToTop from './components/ScrollToTop'
import ShoppingCart from './pages/shoppingCart/ShoppingCart'
import Popup from './components/Popup'
import Verify from './components/Verify'
// import Footer from './components/Footer'
import FooterNew from './components/FooterNew'
import Retail from './pages/retail/Retail'
import Profile from './pages/profile/Profile'
import Favourite from './pages/profile/Favourite'
import RequireAuth from './RequireAuth'
import AuthProvider from './contexts/AuthProvider'
import DemoBooking from './pages/demo/DemoBooking'
import SSOAdditionalInfo from './pages/user/SSOAdditionalInfo'
import Blog from './pages/blog/Blog'
import EmployeeVerify from './pages/user/EmployeeVerify'
import Reservation from './pages/reservation/Reservation'
import SiteMap from './components/SiteMap'
import BackToTopButton from './components/BackToTopButton'
import ZearchToSearch from './components/ZearchToSearch'
// import LoginV3 from './pages/userV3/LoginV3'
import LoginV4 from './pages/userV3/LoginV4'
// import RegisterV3 from './pages/userV3/Registerv3'
import RegisterV4 from './pages/userV3/RegisterV4'
import RegisterForm from './pages/userV3/RegisterForm'
import AccountSelect from './pages/userV3/AccountSelect'
import Payment from './pages/payment/Payment'
// import PaymentSteps from './pages/payment/PaymentSteps'
import PriceTable from './pages/payment/PriceTable'

// import StoreProfile from './pages/store/StoreProfile'

import { useJsApiLoader } from '@react-google-maps/api'
import { Config } from '@Config'
import { getCurrentPosition } from '@services/google.map.service'
import RegisterSteps from './pages/userV3/RegisterSteps'
import { getSetting } from './services/api.service'
import { useQuery } from "@tanstack/react-query"

const excludedHeaderPaths = [
  '/login',
  '/additional',
  '/register',
  '/acc',
  '/recover',
  '/registerv3',
  '/registerv4',
  '/launch-offer',
  '/loginv4'
]
const excludedFooterPaths = [
  '/login',
  '/additional',
  '/register',
  '/acc',
  '/recover',
  '/profile',
  '/registerv3',
  '/registerv4',
  '/launch-offer',
  '/loginv4'
]

const AccessRecord = React.lazy(() => import('./components/AccessRecord'))
/**
 *  The App() component render all
 *  import on to the main application
 */

function App() {
  // state for loading
  const [isLoadedData, setIsLoadedData] = useState(false)
  // const [isLoadedLocation, setIsLoadedLocation] = useState(false)
  // const [isMobile, setIsMobile] = useState(window.innerWidth < 960)
  // get dispatch
  const dispatch = useDispatch()
  // const navigate = useNavigate()
  const pageLocation = useLocation()
  const [libraries] = useState(['places'])
  const location = useSelector(state => state.user.location)
  const isRefreshUserinfo = useSelector(state => state.user.isRefreshUserinfo)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: Config.googleMapsApiKey,
    libraries
  })

  const nearStores = useSelector(state => state.store.nearStores)

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG === 'false') {
      GlobalDebug(false)
    }
    dispatch(setIsMobile(window.innerWidth < 600))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isRefreshUserinfo)
      return
    const tokenLogin = async () => {
      let userinfo = localStorage.getItem('userinfo')
      if (!userinfo) return
      userinfo = JSON.parse(userinfo)
      if (!userinfo || !userinfo.token) return
      try {
        const searchHistory = localStorage.getItem('searchHistory')
        const res = await loginToken(userinfo.token, {
          searchHistory: searchHistory,
        })
        console.log('tokenLogin', res)
        dispatch(setUserinfo(res.data))
        localStorage.removeItem('tmp_id')
      } catch (err) {
        dispatch(logout())
        console.error('tokenLogin failed:', err)
      }
    }
    tokenLogin()
  }, [dispatch, isRefreshUserinfo])

  // console.log(nearStores[0])
  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(setIsMobile(window.innerWidth < 600))
    })
    return () => {
      window.removeEventListener('resize', () => {
        dispatch(setIsMobile(window.innerWidth < 600))
      })
    }
  }, [dispatch])

  // loading data
  useEffect(() => {
    const tokenLogin = async () => {
      let userinfo = localStorage.getItem('userinfo')
      if (!userinfo) return
      userinfo = JSON.parse(userinfo)
      if (!userinfo || !userinfo.token) return
      try {
        const searchHistory = localStorage.getItem('searchHistory')
        const res = await loginToken(userinfo.token, {
          searchHistory: searchHistory,
        })
        console.log('tokenLogin', res)
        dispatch(setUserinfo(res.data))
        localStorage.removeItem('tmp_id')
      } catch (err) {
        dispatch(logout())
        console.error('tokenLogin failed:', err)
      }
    }

    const loadingData = async () => {
      try {
        const setting = await getSetting()
        dispatch(setSetting(setting))
        console.log('setting', setting)

        // load search history
        const searchHistory = localStorage.getItem('searchHistory')
        if (searchHistory) {
          dispatch(setSearchHistory(searchHistory))
        }
        setIsLoadedData(true)
      } catch (error) {
        console.error('loadingData failed: ', error.data?.message)
        setIsLoadedData(true)
      }
    }
    tokenLogin()
    loadingData()
  }, [dispatch])

  // useEffect(() => {
  //   if (!isLoaded || location.isLoaded) return
  //   const fetchCurrentPosition = async () => {
  //     try {
  //       const position = await getCurrentPosition()
  //       if (position) {
  //         console.log('getCurrentPosition useEffect', position)
  //         dispatch(setLocation(position))
  //         await getUserStoreDistance(position)
  //       }
  //       setIsLoadedLocation(true)
  //     } catch (error) {
  //       setIsLoadedLocation(true)
  //       console.error('getCurrentPosition error:', error)
  //       message.error('Failed to get current location')
  //     }
  //   }
  //   fetchCurrentPosition()
  // }, [dispatch, location, isLoaded])

  useQuery({
    queryKey: ['getCurrentPosition',],
    queryFn: async () => {
      const position = await getCurrentPosition()
      if (position) {
        console.log('getCurrentPosition useEffect', position)
        dispatch(setLocation(position))
      }
      return position
    },
    enabled: isLoaded && !location.isLoaded
  })
  // if (position) {
  //   dispatch(setLocation(position))
  // }

  useQuery({
    queryKey: ['getUserStoreDistance', location],
    queryFn: () => getUserStoreDistance(location),
    enabled: location.isLoaded
  })

  // useEffect(() => {
  //   if (!isLoadedLocation)
  //     return
  //   const fetchStoreDistance = async () => {
  //     try {
  //       const res = await getUserStoreDistance(location)
  //       if (res) {
  //         console.log('fetchStoreDistance', res)
  //         dispatch(setStoreDistance(res.data))
  //       }
  //     } catch (error) {
  //       console.error('fetchStoreDistance error:', error)
  //     }
  //   }
  //   fetchStoreDistance()
  // }, [dispatch, location, isLoadedLocation])

  // if not loaded, show loading
  if (!isLoadedData) return <Loading />

  const isPoped = localStorage.getItem('isPoped')

  return (
    <div className='App'>
      <ScrollToTop />
      <BackToTopButton />
      <Suspense>
        <AccessRecord />
      </Suspense>
      {!excludedHeaderPaths.includes(pageLocation.pathname) && <Header />}
      {!isPoped && nearStores.length > 0 && nearStores[0]?.distance > 100 && <Popup />}
      {/* <Popup /> */}
      {/* <Popup /> */}
      <AuthProvider>
        <div className='min-h-[70vh] hide-scroll'>
          <Routes>
            <Route path='/:city?/:store?' element={<Landing />} />
            <Route
              path='/inventoryImporter'
              element={
                // <RequireAuth>
                <InventoryImporter />
                // </RequireAuth>
              }
            />
            {/* <Route path='/search' element={<Zearch />} /> */}
            <Route path='/search' element={<ZearchV2 />} />
            <Route path='/zearch' element={<ZearchToSearch />} />
            <Route path='/stores' element={<PageTemplate type='store' />} />
            {/* <Route path='/store-profile' element={<StoreProfile />} /> */}
            <Route path='/collections' element={<PageTemplate type='collection' />} />
            <Route path='/brands' element={<PageTemplate type='brand' />} />
            <Route path='/register' element={<RegisterV4 />} />
            <Route path='/registerv4' element={<RegisterV4 />} />
            <Route path='/launch-offer' element={<RegisterForm />} />
            <Route path='/additional' element={<SSOAdditionalInfo />} />
            <Route path='/faq' element={<FAQ />} />
            <Route path='/item/:id' element={<ItemDetail />} />
            <Route path='/policy' element={<Policy />} />
            <Route path='/ContactPage' element={<AdditionalContactPage />} />
            <Route path='/acc' element={<AccountSelect />} />
            <Route path='/recover' element={<AccountRecover />} />
            <Route path='/recover/password' element={<ResetPass />} />
            <Route path='/list' element={<ShoppingCart />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/verify' element={<Verify />} />
            <Route path='/retail' element={<Retail />} />
            <Route path='/payment' element={<Payment />} />
            {/* <Route path='/payment-steps' element={<PaymentSteps />} /> */}
            <Route path='/price' element={<PriceTable />} />
            <Route
              path='/profile'
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path='/my-fitzba'
              element={
                <RequireAuth>
                  <Favourite />
                </RequireAuth>
              }
            />
            <Route path='/demo' element={<DemoBooking />} />
            <Route path='/blog/:title?' element={<Blog />} />
            <Route path='/login' element={<LoginV4 />} />
            <Route path='/loginv4' element={<LoginV4 />} />
            <Route path='/reserve' element={<Reservation />} />
            <Route path='/sitemap' element={<SiteMap />} />
            <Route path='/employeeverify' element={<EmployeeVerify />} />
            <Route path='/register-steps' element={<RegisterSteps />} />
          </Routes>
        </div>
        {!excludedFooterPaths.includes(pageLocation.pathname) && <FooterNew />}
      </AuthProvider>
    </div>
  )
}

export default App
